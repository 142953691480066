<template>
  <div>
    <div v-if="$store.getters.shouldBlockReportAccess" style="width: 100%;" class="d-flex justify-center align-center pt-3">
      <v-card outlined max-width="500px">
        <v-card-text>
          Your access to accountability reports is revoked due to your account lock.  You can regain access by unlocking your account from the <router-link to="account">accounts page</router-link>.
        </v-card-text>
      </v-card>
    </div>
    <template v-else>
      <div v-if="showNoImagesMessage && !loading && !busy && !loadingMore" class="pa-3">
        <p style="text-align: center;">No images found...  have you setup your devices to be monitored yet?  If not, then visit the <router-link to="devices">devices</router-link> page</p>
        <p style="text-align: center;">If the monitored device has been used and you expect there to be screenshots, please visit <a :href="`https://${supportSite}/articles/misc/no-events-captured`" target="_blank">this article</a> for steps to restart the {{ appName }} app.</p>
      </div>

      <screenshot-viewer :images="images"
                         :busy="busy"
                         :loading="loading"
                         :show-images="!showNoImagesMessage"
                         :show-filters="!showNoImagesMessage"
                         :can-delete="true"
                         @on-images-deleted="refreshImages"
                         @on-refresh-images="refreshImages"
                         v-show="$store.getters.termsAccepted"></screenshot-viewer>

      <v-btn class="centered mb-5 text-center"
             v-if="(!showNoImagesMessage && !loading && !busy && endDay < 7) || loadingMore"
             @click="incrementAndLoadImagesAwait(2)"
             color="primary"
             outlined
             :loading="loadingMore">
        Load More
      </v-btn>
    </template>

  </div>
</template>

<script>
  import logging from "../lib/logging"
  import ScreenshotViewer from "./ScreenshotViewer"
  import StoreConstants from  '../StoreConstants'
  import Constants from "../Constants";
  import ImageUtils from "../services/ImageUtils";

  const imageLoadIncrement = 0.5

  let refreshImagesTimeout = null

  let unsubscribeFunctions = []

  export default {
    name: 'home',
    components: {
      ScreenshotViewer
    },
    mounted() {
    },
    created () {
      this.loading = true
      // this.loadImagesAwait()
      this.identitiesCheck()

      this.showBanner = !localStorage.getItem('never-show-banner-again')
    },
    data () {
      return {
        supportSite: process.env.VUE_APP_SUPPORT_DOMAIN,
        appName: process.env.VUE_APP_NAME,
        showBanner: true,
        images: [],
        busy: true,
        isSubscribed: false,
        loading: true,
        loadingMore: false,
        startDay: 0,
        endDay: imageLoadIncrement,
        showRefreshImages: false
      }
    },
    beforeDestroy () {
      this.unsubscribeFromStore()
    },
    computed: {
      showNoImagesMessage() {
        return this.images.length === 0
      }
    },
    methods: {
      neverShowBannerAgain() {
        localStorage.setItem('never-show-banner-again', 'true')
        this.showBanner = false
      },
      async refreshImages() {
        this.loading = true
        this.startDay = 0
        this.endDay = imageLoadIncrement
        this.images = []
        await this.loadImagesAwait()
      },
      unsubscribeFromStore() {
        for(let u of unsubscribeFunctions) {
          u();
          unsubscribeFunctions.pop();
        }
      },
      async incrementAndLoadImagesAwait(times) {
        times = times || 1

        for(let c = 0; c < times; c++) {
          this.loadingMore = true
          this.startDay += imageLoadIncrement
          this.endDay += imageLoadIncrement
          await this.loadImagesAwait()
        }
      },
      handleRefreshImagesButton() {
        if(refreshImagesTimeout !== null) {
          clearTimeout(refreshImagesTimeout)
        }

        this.showRefreshImages = false
        refreshImagesTimeout = setTimeout(() => {
          if(this.images && this.images.length > 0) {
            this.showRefreshImages = true
            refreshImagesTimeout = null
          }
        }, 15000)
      },
      async loadImagesAwait() {

        if(this.$store.getters.shouldBlockReportAccess) {
          this.loading = false
          return
        }

        this.handleRefreshImagesButton()

        try {

          let identitiesToLoad = this.$store.state.account.account.identities.filter(x => { return x.takeSnapshots }).map(identity => {
            return identity.id
          })

          let promises = []
          for(const id of identitiesToLoad) {
            promises.push(this.api.getImageRecordsAwait(this.startDay, this.endDay, id, null))
          }

          let arrayOfResults = await Promise.all(promises)

          let results = []
          for(let result of arrayOfResults) {
            if(result.data) {

              //we can remove this once the app update that fixed rotated image ratios is common enough.  Launched july 2020
              let { largestRatio } = ImageUtils.getImageRatioInfo(result.data)

              for(let i of result.data) {
                if(i.downloadUrl === Constants.insufficientSubscriptionUrl) {
                  i.imageRatio = Constants.insufficientSubscriptionImageRatio
                  i.encrypted = false
                } else {
                  i.imageRatio = largestRatio
                }
                results.push(i)
              }
            }
          }

          let tempImages = this.images.slice()

          tempImages = tempImages.concat(results)

          let filteredImages = []
          let fileKeySet = new Set()
          for(let i of tempImages) {
            if(fileKeySet.has(i.fileKey)) {
              // eslint-disable-next-line no-console
              console.log(`image ${i.fileKey} duplicate (${new Date(i.takenAt).toLocaleString()})`)
              continue
            }

            fileKeySet.add(i.fileKey)
            filteredImages.push(i)
          }

          filteredImages.sort((a, b) => {
            if (a.takenAt < b.takenAt) {
              return 1
            } else if (a.takenAt > b.takenAt) {
              return -1
            } else {
              return 0
            }
          })

          this.images = filteredImages

        } catch (error) {
          console.log(error)
          logging.notify(error)
          alert('error loading images... please try again.  If this continues, contact support@truple.io')
        } finally {
          this.loading = false
          this.busy = false
          this.loadingMore = false

          this.unsubscribeFromStore()
        }

        if (this.endDay < 1 && this.images.length < 16) {
          console.log(`this.images.length = ${this.images.length}`)
          this.incrementAndLoadImagesAwait(1)
        }
      },
      identitiesCheck() {
        let unsubscribe = this.$store.subscribe((mutation, state) => {
          if(mutation.type === StoreConstants.commits.setAccount) {
            this.loadImagesAwait()
          }
        })

        unsubscribeFunctions.push(unsubscribe)

        if(this.$store.state.account && this.$store.state.account.account) {
          this.loadImagesAwait()
        }
      }
    }
  }
</script>

<style lang="less" scoped>

</style>